import React from "react";
import {
  buildEmailRule,
  DataProvider,
  IAuthSignUpResponseModel,
  IErrorModel,
  useMutation,
} from "@nf/common";
import styles from "./SignUpForm.module.scss";
import { Form, FormButton, LabelField } from "../../../../Form";
import { Input } from "../../../../Inputs";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { SignUpContainer } from "./SignUpContainer";
import { googleAnalyticsEvent } from "lib/gtm";
import { useRouter } from "next/router";

const SignUpSuccess = dynamic(() => import("./SignUpSuccess"));

type TSignUpForm = {
  title?: string;
};

interface SignUpFormValues {
  email: string;
}

const getRedirectUrl = (error: IErrorModel | null) => {
  if (!Array.isArray(error?.Data)) {
    const redirectUrl = error?.Data?.redirect_url;

    return redirectUrl;
  }
};

export const SignUpFormForm: React.FC<TSignUpForm> = ({ title }) => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const query = router.query;

  const {
    mutate: signUp,
    reset,
    error,
    isLoading,
    isSuccess,
  } = useMutation<IAuthSignUpResponseModel, IErrorModel, SignUpFormValues>(
    (data) =>
      DataProvider.signUp({
        Email: data.email,
        UtmSource: query.utm_source ? `${query.utm_source}` : undefined,
        UtmMedium: query.utm_medium ? `${query.utm_medium}` : undefined,
        UtmCampaign: query.utm_campaign ? `${query.utm_campaign}` : undefined,
      }),
    {
      onError: (e) => {
        const redirectUrl = getRedirectUrl(e);
        if (redirectUrl) {
          router.push(redirectUrl);
        }
      },
    }
  );

  const onFinish = async (values: SignUpFormValues) => {
    if (values.email) {
      googleAnalyticsEvent.signUp(values.email);

      signUp(values);
    }
  };

  const hasRedirectUrl = getRedirectUrl(error);
  const hasError = error && !hasRedirectUrl;

  if (isSuccess) {
    return <SignUpSuccess reset={reset} />;
  }

  return (
    <>
      <SignUpContainer title={title} />
      <div className={styles.formContainer}>
        <Form onFinish={onFinish}>
          <LabelField
            name="email"
            validateTrigger={["onSubmit", "onBlur"]}
            label={t("web_signup_create_account_user_email_field", "")}
            rules={[buildEmailRule(t)]}
            otherErrors={
              hasError ? [t("web_password_error_header")] : undefined
            }
          >
            <Input
              autoFocus
              spellCheck="false"
              trimValue
              placeholder={t("web_login_email_field", "")}
              className={styles.loginInput}
              disabled={isLoading}
            />
          </LabelField>

          <div className={styles.formButton}>
            <FormButton loading={isLoading}>
              {t("web_common_start_watching")}
            </FormButton>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignUpFormForm;
