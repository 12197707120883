import { useConfigurationSelector } from "@nf/common";
import React from "react";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import cx from "classnames";

import styles from "./SignUpForm.module.scss";

const HeaderLogo = dynamic(
  () => import("components/AppHeader/components/HeaderLogo"),
  { ssr: false }
);

type TOfferItem = {
  price: number;
  label: string;
  header: string;
  pricePrefix: string;
  displaySave?: boolean;
};

const offersList: TOfferItem[] = [
  {
    price: 59,
    header: "web_landing_page_pay_monthly",
    label: "web_payment_period_month",
    pricePrefix: "kr",
  },
  {
    price: 590,
    header: "web_landing_page_pay_annual",
    label: "web_payment_period_year",
    pricePrefix: "kr",
  },
];

const OfferListItem: React.FC<TOfferItem> = ({
  price,
  label,
  header,
  pricePrefix,
  displaySave = false,
}) => {
  const { t } = useTranslation("translations");
  return (
    <div className={styles.offerItem} key={`offer-item-${label}=${price}`}>
      {displaySave && (
        <div className={styles.labelSave}>
          {t("web_choose_subscription_annual_save", "")}
        </div>
      )}
      <h3>{t(header)}</h3>
      <div>
        <span>{price}</span>
        <span>
          {pricePrefix}
          {t(label)}
        </span>
      </div>
    </div>
  );
};

export const SignUpContainer = ({ title }: { title?: string }) => {
  const configuration = useConfigurationSelector();
  const [offer1, offer2] = offersList;
  return (
    <>
      <HeaderLogo configuration={configuration} className={styles.headerLogo} />
      <div className={styles.signUpContainer}>
        <h2>{title}</h2>
        <div className={styles.offerList}>
          <div className={styles.offerRow}>
            <OfferListItem
              price={offer1.price}
              label={offer1.label}
              header={offer1.header}
              pricePrefix={offer1.pricePrefix}
            />
            <div className={cx(styles.offerBorder)} />
            <OfferListItem
              price={offer2.price}
              label={offer2.label}
              header={offer2.header}
              pricePrefix={offer2.pricePrefix}
              displaySave
            />
          </div>
        </div>
      </div>
    </>
  );
};
